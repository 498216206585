<template>
  <div class="h-screen grid grid-cols-1 lg:grid-cols-2">
    <!--    Left col-->
    <div class="flex flex-col items-center justify-center gradient text-white relative overflow-hidden">
      <Blur class="absolute left-0 top-0 z-0"/>
      <div class="relative z-10 px-36">
        <TalentPondLogo class="-mt-32 mb-24 w-16 h-16"/>
        <h2 class="text-white text-5xl md:text-7xl font-semibold mb-4 font-poppins">Talent Pond</h2>
        <p class="mb-6 body-l-400">Talent Pond helps businesses thrive by connecting them with a network of talented professionals who are ready and willing to work remotely. Get started today.</p>
        <ButtonIcon @onClick="$router.push({name:'UserType'})" text="Sign up for free" class="w-full mb-5"/>
        <ButtonIcon
          @onClick="$router.push({name:'Login'})"
          text="Log in"
          class="w-full"
          background="bg-transparent"
          border="border border-white"
          hoverBackground="hover:bg-white hover:bg-opacity-10"
        />
      </div>
    </div>
    <!--    Right col-->
    <div class="bg-cream relative overflow-hidden hidden lg:block">
      <img class="absolute right-0 top-0 mt-32" src="@/assets/images/talentpond_preview.png">
    </div>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import Blur from "@/components/shared/svg/Blur";
import TalentPondLogo from "@/components/shared/svg/TalentPondLogo";

export default {
  name: "Welcome",
  components: { TalentPondLogo, Blur, ButtonIcon }
}
</script>

<style scoped>

</style>
